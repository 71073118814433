/**
 * Created by 程雨喵'mac on 2024/7/2.
 * Copyright © 2024年 云柜-金星晖. All rights reserved.
 * 功能：办事处
 */
import BaseApi from '@/request/base/BaseApi';

class OfficeApiSet {
  constructor () {
    this.qryOffice = BaseApi.createModel('获取办事处信息列表', '/opmp/office/qryOffice').setMethod(1);
    this.addOffice = BaseApi.createModel('新增办事处', '/opmp/office/addOffice').setMethod(1);
    this.updateOffice = BaseApi.createModel('更新办事处', '/opmp/office/updateOffice').setMethod(1);
    this.qryOfficeDetail = BaseApi.createModel('办事处详情', '/opmp/office/qryOfficeDetail').setMethod(1);
  }
}

export default new OfficeApiSet();
