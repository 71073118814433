import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isPhone } from '@/libs/regularUtils';

export class OfficeDataListModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    this.officeName = ''; // 办事处名称
    this.officeCode = ''; // 办事处编码
    this.directorName = ''; // 办事处总监姓名
    this.directorTel = ''; // 办事处总监手机
    this.updateTime = ''; // 更新时间
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        parentOrg: '吾行畅充服务商',
        officeName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 20) + 10),
        officeCode: BaseModel.getFakeNumber(99999999, 0),
        directorName: BaseModel.getFakeTitle(5),
        directorTel: '139042880' + (BaseModel.getFakeNumber(80, 0) + 10),
        updateTime: '2023-12-31 12:59:59'
      };
      list.push(dic);
    }
    return list;
  }
}
export class OfficeDataDetailModel extends OfficeDataListModel {
  constructor () {
    super();
    this.parentOrg = '';
    this.provinceScope = []; // 城市数据
    this._h5CityName = '';
    this.h5SubmitCityList = ''; // 提交时的纯小区数组
  }

  set h5CityName (v) {
    this._h5CityName = v;
  }

  get h5CityName () {
    let _h5CityName = '';
    let len = 0;
    const provinceScope = this.provinceScope;
    if (provinceScope.length === 0) {
      _h5CityName = '暂未选择任何城市';
    } else {
      const strArr = [];
      for (let i = 0; i < provinceScope.length; i++) {
        const p = provinceScope[i];
        if (!p.childList || !p.childList.length) continue;
        len += p.childList.length;
        for (let j = 0; j < p.childList.length; j++) {
          if (strArr.length < 5) {
            const v = p.childList[j];
            strArr.push(v.cityName || v.name);
          } else {
            break;
          }
        }
      }
      const str = strArr.join(',');
      _h5CityName = `已选择${str}等${len}个城市`;
    }
    this._h5CityName = _h5CityName;
    return this._h5CityName;
  }

  initData (resData) {
    super.initData(resData);
    // this.principalType = 3;
    if (BaseModel.checkArray(this.provinceScope)) {
      let arr = [];
      this.provinceScope.forEach(v => {
        arr = [...arr, ...v.childList];
      });
      this.h5SubmitCityList = arr;
    }
    return this;
  }

  /**
   * 数据具体校验-新增/编辑
   * @returns {*}
   */
  checkValidSubmit (isAdd = true) {
    if (this.h5SubmitCityList.length === 0) {
      return BaseModel.getValidRes(false, '请选择城市');
    }
    if (this.officeName.length > 64) {
      return BaseModel.getValidRes(false, '办事处名称需要小于64位');
    }
    if (this.directorName.length > 20) {
      return BaseModel.getValidRes(false, '办事处总监姓名需要小于20位');
    }
    if (!isPhone(this.directorTel)) {
      return BaseModel.getValidRes(false, '请输入正确的手机号');
    }
    return BaseModel.getValidRes(true, '', {
      officeName: this.officeName,
      directorName: this.directorName,
      directorTel: this.directorTel,
      city: this.h5SubmitCityList
    });
  }

  static createFakeData () {
    return this.createFakeDataList(1)[0];
  }

  static createAdditionModel () {
    return new this();
  }
}
