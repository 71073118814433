<!--
    Created by 程雨喵'mac on 2024/7/4.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
.office-city-detail {
  padding-bottom: @containerGap;
  .p-row {
    padding: @containerGap;
    background-color: @backColorStrong;
    border-radius: @borderRadiusMid;
    &+.p-row {
      margin-top: @containerGap;
    }
    .h4 .small {
      margin-left: 10px;
      font-size: 12px;
      color: @textColorLight;
      font-weight: 400;
    }
    .p-con {
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;
      .c-row {
        margin: 5px;
      }
    }
  }
}
</style>

<template>
  <yg-drawer
    title="已选城市"
    width="600"
    :back-type="2"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="office-city-detail"
    >
      <div
        v-for="(item, i) in dataList"
        :key="i"
        class="p-row"
      >
        <div class="h4">
          {{ item.name }}
          <span class="small">已选{{ item.childList.length }}个城市</span>
        </div>
        <div class="p-con">
          <div
            v-for="(sub, s) in item.childList"
            :key="'s' + s"
            class="c-row"
          >
            <div>{{ sub.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
export default {
  components: {},
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {};
  },
  methods: {
    onClose () {
      this.$emit('on-close');
    }
  }
};
</script>
