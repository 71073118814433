export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('officeName', '办事处名称', 160).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('officeCode', '办事处编码').setSpan({ canCopy: true }),
        BaseTableColumn.initData('concat', '办事处总监').setWidth(160).setKeyValueList([
          { label: '名称：', labelWidth: 40, key: 'directorName', attr: { canTooltip: true } },
          { label: '手机：', labelWidth: 40, key: 'directorTel', attr: { canHidePhone: true } }
        ]),
        BaseTableColumn.initData('updateTime', '更新时间'),
        BaseTableColumn.initData('setting', '操作').setWidth(100).setAlign('center').setButtons([
          { title: '编辑', name: 1, permission: 'system:office:updateOffice' },
          { title: '详情', name: 2, permission: 'system:office:qryOfficeDetail' }
        ], ({ name }, row, index) => {
          this.dialog.settingType = name;
          this.requestDetail(row);
        })
      ]
    };
  }
};
