<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：组织管理-办事处管理
-->
<style lang="less">
</style>

<template>
  <section>
    <div
      v-permission="'system:office:qryOffice'"
    >
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:office:qryOffice'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：设置-->
    <v-setting-submit
      v-if="!!dialog.settingModel"
      :setting-type="dialog.settingType"
      :model="dialog.settingModel"
      @on-close="onCloseSubmit"
      @on-sure="onSureSubmit"
    />
  </section>
</template>

<script>
import OfficeApiSet from '@/request/api/OfficeApiSet';
import { OfficeDataListModel, OfficeDataDetailModel } from './model/OfficeDataModel';
import ColumnMixin from './mixin/tableColumn';
import vSettingSubmit from './view/submit.vue';
export default {
  components: { vSettingSubmit },
  mixins: [ColumnMixin],
  data () {
    return {
      testLoading: false,
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:office:addOffice'),
        BaseSettingModel.initData('刷新', 'refresh', 'md-refresh').setPermission('system:office:qryOffice')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        settingModel: null,
        settingType: 0
      }
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onCloseSubmit (needRefresh) {
      if (needRefresh) {
        this.onChangePage(1);
      }
      this.dialog.settingType = false;
      this.dialog.settingModel = null;
    },
    onSureSubmit () {
      const p = this.dialog.settingModel.checkValidSubmit();
      if (p.success) {
        this.requestSubmit(p.params);
      } else {
        this.$Message.warning(p.msg);
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'add': {
        this.dialog.settingType = 0;
        // parentOrg
        const model = OfficeDataDetailModel.createAdditionModel();
        model.parentOrg = this.$storage.merchantInfo.providerName || '-';
        this.dialog.settingModel = model;
      }
        break;
      case 'refresh':
        this.onChangePage(1);
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = OfficeApiSet.qryOffice;
      api.params = {
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = OfficeDataListModel.initListWithDataList(resData.rows);
      });
      // const resList = OfficeDataListModel.createFakeDataList();
      // console.log(resList);
      // this.page.total = resList.length;
      // this.tableData = OfficeDataListModel.initListWithDataList(resList);
    },
    requestSubmit (p) {
      let api = null;
      if (this.dialog.settingType === 0) {
        api = OfficeApiSet.addOffice;
        api.params = p;
      } else {
        api = OfficeApiSet.updateOffice;
        api.params = p;
        api.params.officeCode = this.dialog.settingModel.officeCode;
      }
      this.$http(api).then(res => {
        this.dialog.settingModel = null;
        this.$Message.success('操作成功');
        this.onChangePage(1);
      });
    },
    requestDetail (row) {
      const api = OfficeApiSet.qryOfficeDetail;
      api.params = {
        id: row.id,
        officeCode: row.officeCode
      };
      this.$http(api).then(res => {
        res.data.platformName = row.platformName;
        this.dialog.settingModel = OfficeDataDetailModel.initModelWithData(res.data);
      });
      // const resData = OfficeDataDetailModel.createFakeData();
      // this.dialog.settingModel = OfficeDataDetailModel.initModelWithData(resData);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
