<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    功能概述：办事处-提交
-->
<style lang="less">
.office-submit {
  .office-submit-content {
    .office-detail-text {
      line-height: @formHeight;
    }
    .table-outer {
      .office-submit-table {
        border: 1px solid @lineColorNormal;
        border-bottom: 0;
        border-radius: @borderRadiusMin;
        .table-row {
          display: flex;
          align-items: center;
          border-bottom: 1px solid @lineColorNormal;
          line-height: 24px;
          font-size: 12px;
          &.table-header {
            background-color: @backColorNormal;
            color: @textColorLight;
            font-weight: 600;
            font-size: 13px;
            .table-col {
              justify-content: center;
            }
          }
          .table-col {
            padding: 8px 5px;
            .display-flex();
            flex-wrap: wrap;
            &-1 {
              justify-content: flex-start;
              border-right: 1px solid @lineColorNormal;
              flex: 1;
            }
            &.no-data {
              justify-content:center;
              color: @textColorLight;
            }
            &-2 {
              .flex-grow(120px);
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <yg-modal
    width="600"
    :title="modalTitle"
    :back-type="2"
    sure-text="保存"
    :show-bottom="settingType !== 2"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 200px"
      class="office-submit"
      :class="modalClass"
    >
      <div class="office-submit-content">
        <yg-card
          v-for="(item, index) in formItems"
          :key="index"
          style="margin-bottom: 15px"
          :title="item.title"
        >
          <div
            slot="content"
          >
            <Form
              v-if="index === 0"
            >
              <FormItem
                v-for="(sub, ind) in item.items"
                :key="ind"
                :label="sub.label"
                :label-width="sub.labelWidth"
                :required="sub.isRequired && settingType !== 2"
              >
                <Input
                  v-if="sub.tagName === 'Input' && settingType !== 2"
                  v-model="model[sub.key]"
                  :placeholder="'请输入' + sub.label"
                  :disabled="sub.disabled"
                  @input="checkSubmit"
                />
                <yg-attribute-span
                  v-else
                  :can-hide-phone="true"
                  :text="model[sub.key]"
                  class="office-detail-text"
                />
              </FormItem>
            </Form>
            <!--可见小区扩展：指定小区-->
            <div
              v-else
              class="table-outer"
            >
              <div
                class="office-submit-table"
              >
                <div class="table-row table-header">
                  <div class="table-col table-col-1">
                    已选城市
                  </div>
                  <div class="table-col table-col-2">
                    操作
                  </div>
                </div>
                <div class="table-row">
                  <div
                    class="table-col table-col-1 no-data"
                  >
                    {{ model.h5CityName }}
                  </div>
                  <div class="table-col table-col-2">
                    <yg-table-buttons
                      :items="settingButtons"
                      @on-click="_clickChoose"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </yg-card>
      </div>
      <!--弹窗：选择小区-->
      <yg-region-selector
        v-if="dialog.selectorShow"
        :data="dialog.dataList"
        @on-close="dialog.selectorShow = false"
        @on-sure="onSureRegionDialog"
      />
      <!--弹窗：小区详情-->
      <v-detail
        v-if="dialog.detailShow"
        :data-list="model.provinceScope"
        @on-close="dialog.detailShow = false"
      />
    </div>
  </yg-modal>
</template>

<script>
import SpApiSet from '@/request/api/SpApiSet';
import vDetail from './cityDetails.vue';
export default {
  components: { vDetail },
  mixins: [],
  props: {
    /**
     * 0: 新增
     * 1：编辑
     * 2：详情
     */
    settingType: {
      type: Number,
      default: 0
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '数据无变化',
      // 表单数据
      formItems: [
        {
          title: '基本信息',
          items: [
            {
              label: '上级组织',
              labelWidth: 140,
              key: 'parentOrg',
              tagName: 'Input',
              disabled: true,
              isRequired: true
            },
            {
              label: '办事处编码',
              labelWidth: 140,
              key: 'officeCode',
              tagName: 'Input',
              disabled: true,
              isRequired: false
            },
            {
              label: '办事处名称',
              labelWidth: 140,
              key: 'officeName',
              tagName: 'Input',
              disabled: false,
              isRequired: true
            },
            {
              label: '办事处总监姓名',
              labelWidth: 140,
              key: 'directorName',
              tagName: 'Input',
              disabled: false,
              isRequired: true
            },
            {
              label: '办事处总监手机',
              labelWidth: 140,
              key: 'directorTel',
              tagName: 'Input',
              disabled: false,
              isRequired: true
            }
          ]
        },
        {
          title: '城市范围',
          isCityChoose: true
        }
      ],
      dialog: {
        detailShow: false,
        selectorShow: false,
        dataList: []
      },
      needRefreshList: false,
      regionDialog: {}
    };
  },
  computed: {
    settingButtons () {
      if (!this.model.provinceScope.length) {
        return [{ title: '请选择', name: 2 }];
      }
      if (this.settingType !== 2) {
        return [
          { title: '编辑', name: 0 },
          { title: '详情', name: 1 }
        ];
      }
      return [
        { title: '详情', name: 1 }
      ];
    },
    modalClass () {
      let classStr = '';
      switch (this.settingType) {
      case 0:
        classStr = 'office-add';
        break;
      case 1:
        classStr = 'office-edit';
        break;
      case 2:
        classStr = 'office-detail';
        break;
      }
      return classStr;
    },
    modalTitle () {
      let str = '';
      switch (this.settingType) {
      case 0:
        str = '新增办事处';
        break;
      case 1:
        str = '编辑办事处';
        break;
      case 2:
        str = '办事处详情';
        break;
      }
      return str;
    }
  },
  created () {
    if (this.settingType === 0) {
      // 新增时 没有办事处编码
      const index = this.formItems[0].items.findIndex(v => v.key === 'officeCode');
      this.formItems[0].items.splice(index, 1);
    }
  },
  methods: {
    onSure () {
      this.$emit('on-sure');
    },
    onClose () {
      this.$emit('on-close', this.needRefreshList);
    },
    onSureRegionDialog ({ list, treeList }) {
      const newList = list.filter(v => v.cate === 2);
      this.model.provinceScope = treeList;
      this.model.h5SubmitCityList = newList;
      this.dialog.selectorShow = false;
      this.checkSubmit();
    },
    _clickChoose ({ name }) {
      let list = [];
      this.model.provinceScope.forEach(v => {
        list = [...list, ...v.childList];
      });
      this.dialog.dataList = list;
      if (name === 1) {
        this.dialog.detailShow = true;
      } else {
        this.dialog.selectorShow = true;
      }
    },
    onChangeDisabled ({ disabled, msg }) {
      this.buttonDisabled = disabled;
      this.buttonDisabledMsg = msg;
    },
    checkSubmit () {
      const item = this.formItems[0].items.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
      // if (!this.buttonDisabled) {}
    },
    requestSubmit (type, params) {
      let api = {};
      if (type === 0) {
        // 登录账号
        api = SpApiSet.spAdminSet;
      } else if (type === 1) {
        // 联系人
        api = SpApiSet.spConcatSet;
      }
      api.params = params;
      this.$http(api).then(res => {
        this.needRefreshList = true;
        if (type === 0) {
          // 提交后就不可编辑了
          this.model.h5AdminCanChange = false;
        } else if (type === 0) {
          this.model.code = '';
        }
        this.onChangeDisabled({ disabled: true, msg: '无数据变更' });
        this.$Message.success('操作成功');
        this.submitFinish();
      });
    }
  }
};
</script>
